import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
import Signs from "./components/signs";

mapboxgl.accessToken =
  "pk.eyJ1IjoianVsaWFuayIsImEiOiJjaXc5eXMxamkwMDFlMnlsb2hxc2l2ZWwxIn0.jgKk_N8Fwm1fj4Qa29NUdw";

  const Map = () => {
    const mapContainerRef = useRef(null);
    const initialDistance = parseInt(localStorage.getItem('total')) || 0;
  
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [newLat, setNewLat] = useState(null);
    const [newLng, setNewLng] = useState(null);
    const [speed, setSpeed] = useState(0);
    const [heading, setHeading] = useState(null);
    const [distance, setDistance] = useState(0);
    const [totalDistance, setTotalDistance] = useState(initialDistance);
    const [zoom, setZoom] = useState(15);
    const [totalActive, setTotalActive] = useState(false);
    // Initialize map when component mounts
    useEffect(() => {
      if(localStorage.getItem('total') !== null){
        setTotalDistance(localStorage.getItem('total'))
      }
    }, []);

    useEffect(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
      });
      navigator.geolocation.watchPosition((position) => {
        setNewLat(position.coords.latitude)
        setNewLng(position.coords.longitude)
        setSpeed(Math.round(position.coords.speed * 3.6))
        setHeading(position.coords.heading)
      });

      setDistance(calculateDistance(lat, lng, newLat, newLng));
      
      setTotalDistance(distance + totalDistance);


      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/juliank/cl7xrdnp2001j14qxffkikmz7",
        center: [lng, lat],
        zoom: zoom
      });
  
      map.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true
        },
        trackUserLocation: true,
        showUserHeading: true
      }), "bottom-left");
  
      map.on("move", () => {
        setLng(map.getCenter().lng.toFixed(4));
        setLat(map.getCenter().lat.toFixed(4));
        setZoom(map.getZoom().toFixed(2));
      });
  
      // Clean up on unmount
      return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    localStorage.setItem('total', totalDistance);
      
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      var R = 6371; // km
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1); 
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
      var d = R * c;
      return d;
    }
    const toRad = (value) => {
      return value * Math.PI / 180;
    }

    return (
      <div>
        <div className="dashboard">
          <div className="speed">
            <p>{speed}</p><span>km/h</span>
            <div onClick={() => setTotalActive(!totalActive)}>{!totalActive ? `${distance.toFixed(2)} km ab Start` : `${totalDistance.toFixed(2)} km Gesamt`}</div>
          </div>
          {heading}
          <div className="sidebarSigns">
            <Signs speed="6" />
          </div>
        </div>
        {/* <div className="cta">
          <button className="cta-button">
            <span className="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 1.1C6 0.492487 6.49249 0 7.1 0H8.9C9.50751 0 10 0.492488 10 1.1V14.9C10 15.5075 9.50751 16 8.9 16H7.1C6.49249 16 6 15.5075 6 14.9V1.1Z" fill="white"/>
                <path d="M1.1 6H14.9C15.5075 6 16 6.49249 16 7.1V8.9C16 9.50751 15.5075 10 14.9 10H1.1C0.492487 10 0 9.50751 0 8.9V7.1C0 6.49249 0.492488 6 1.1 6Z" fill="white"/>
              </svg>
            </span>
            Add Dashboat to homescreen
          </button>
        </div> */}
        <div className="gradient"></div>
        <div className="map-container" ref={mapContainerRef} />
      </div>
    );
  };
  
  export default Map;