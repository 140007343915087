import React, {useState} from 'react';
import SpeedSign from "../signs/speed";
import Begegnungsverbot from "../signs/begegnungsverbot";
import Wellenschlag from "../signs/wellenschlag";

const Signs = (props) => {
    const initialValues = [
        <SpeedSign speed="6" />, 
        <Begegnungsverbot />, 
        <Wellenschlag />,
    ]
    const [showSigns, setShowSigns] = useState(new Set())
    const [allSigns, setAllSigns] = useState(new Set(initialValues))


    const handleSigns = () => {
        const signs = new Set(allSigns)
        // do your thing
        setAllSigns(signs)
    }

    return(
        <>
        {/* <SpeedSign speed={props.speed} />
        <Wellenschlag />
        <Begegnungsverbot /> */}
        {[...allSigns].map(sign => {
            console.log(sign);
            return <>{sign}</>
        })}
        </>
    )
}

export default Signs;